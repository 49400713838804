[data-sidebar-style="overlay"] {
    .deznav {
        left: -100%;
        @include transitionMedium;
		
		.metismenu{
			& > li {
				padding: 0 30px;
				& > a {
					font-size: 16px;
					padding: 20px 20px;
					color: #7f7184;
					border-radius: 0.75rem;
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
				}
				&:hover > a,
				&.mm-active > a {
					background: $primary;
					color:$white;
					box-shadow:0 12px 15px 0 rgba($primary, 0.13);
				}
				@include respond ('phone-land'){
					padding:0 15px;
				}
			}
			ul a{
			    padding-top: .5rem;
				padding-bottom: .5rem;
				position: relative;
				font-size: 15px;
				padding-left: 3.5rem;
			}
		}
		
		
		@at-root [direction="rtl"]#{&} {
            left: auto;
            right: -100%;
        }
    }
    .content-body {
        margin-left: 0;
    }
    .nav-header {
        position: absolute;
        .hamburger.is-active {
            left: 0;
            .line {
                background-color: $primary;
            }
        }
    }
    .menu-toggle {
        .nav-header {
            position: absolute;
            left: auto;
        }
        .deznav {
            left: 0;
            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: 0;
            }
        }
    }
    .footer {
        padding-left: 0;
    }
}

[data-sidebar-style="overlay"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}

[data-sidebar-position="fixed"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}